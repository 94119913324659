<template>
  <div>
    <Header :url="require('@/assets/images/about.png')"/>
    <Menu :menuList="menuList"></Menu>
    <Footer></Footer>
  </div>
</template>

<script>
export default {
  name: "about",
  data(){
    return{
      menuList: [
        {
          id: 'a1',
          name: '全部会员',
        }, {
          id: 'a2',
          name: '专业会员',
        }, {
          id: 'a3',
          name: '团体会员',
        }, {
          id: 'a4',
          name: '学生会员',
        },{
          id: 'a5',
          name: '联系会员',
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
